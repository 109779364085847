// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Skill_skill__l3rDc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 115px;
  max-width: 123px;
  color: #888888;
  background-color: #1A1A1A;
  border: 1px solid rgb(36, 36, 36);
  padding: 35px 0px 30px 0px;
  border-radius: 30px;
  font-weight: 400;
  transition: transform 0.3s ease-in-out, border 0.3s ease-in-out, background 0.3s ease-in-out, color 0.3s ease-in-out;
  flex: 1 1 100px;
}
.Skill_skill__l3rDc .Skill_test__q0-7R {
  transition: opacity 0.3s ease-in-out;
}
.Skill_skill__l3rDc .Skill_test2__D01aZ {
  transition: opacity 0.3s ease-in-out;
}
.Skill_skill__l3rDc img {
  position: absolute;
}
.Skill_skill__l3rDc > div {
  margin-top: 90px;
}
.Skill_skill__l3rDc:hover {
  transform: translateY(-10%);
  border: 1px solid #7c3b3b;
  background: #1E1414;
  color: #BB5858;
}

/* 
@media (min-width: 1131px) and (max-width: 1680px) {
  .skill{
    max-width: 82px;

    font-size: 12px;
    padding: 20px 0px 15px 0px;

    border-radius: 18px;
  }

  .skill >div{
    margin-top: 60px;
  }
}


@media (max-width: 1130px) {
  .skill{
    flex: 1 1 120px;
    max-width: 140px;

    box-sizing: border-box;
  }

} */`, "",{"version":3,"sources":["webpack://./src/components/Skill.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EAEA,8BAAA;EACA,mBAAA;EAEA,WAAA;EACA,gBAAA;EACA,gBAAA;EAEA,cAAA;EACA,yBAAA;EAEA,iCAAA;EACA,0BAAA;EAEA,mBAAA;EACA,gBAAA;EAEA,oHAAA;EACA,eAAA;AANF;AAQE;EACE,oCAAA;AANJ;AASE;EACE,oCAAA;AAPJ;AAUE;EACE,kBAAA;AARJ;AAYE;EACE,gBAAA;AAVJ;AAcE;EACE,2BAAA;EACA,yBAAA;EACA,mBAAA;EACA,cAAA;AAZJ;;AAkBA;;;;;;;;;;;;;;;;;;;;;;;;;GAAA","sourcesContent":["\n.skill{\n  display: flex;\n  flex-direction: column;\n\n  justify-content: space-between;\n  align-items: center;\n\n  width: 100%;\n  min-width: 115px;\n  max-width: 123px;\n\n  color: #888888;\n  background-color: #1A1A1A;\n\n  border: 1px solid rgb(36, 36, 36);\n  padding: 35px 0px 30px 0px;\n\n  border-radius: 30px;\n  font-weight: 400;\n\n  transition: transform 0.3s ease-in-out, border 0.3s ease-in-out, background 0.3s ease-in-out, color 0.3s ease-in-out; \n  flex: 1 1 100px;\n  \n  .test{\n    transition: opacity 0.3s ease-in-out;\n  }\n\n  .test2{\n    transition: opacity 0.3s ease-in-out;\n  }\n  \n  img{\n    position: absolute;\n\n  }\n\n  >div{\n    margin-top: 90px;\n  }\n\n\n  &:hover{\n    transform: translateY(-10%);\n    border: 1px solid #7c3b3b;\n    background: #1E1414;\n    color: #BB5858;\n    \n  }\n\n}\n\n/* \n@media (min-width: 1131px) and (max-width: 1680px) {\n  .skill{\n    max-width: 82px;\n\n    font-size: 12px;\n    padding: 20px 0px 15px 0px;\n\n    border-radius: 18px;\n  }\n\n  .skill >div{\n    margin-top: 60px;\n  }\n}\n\n\n@media (max-width: 1130px) {\n  .skill{\n    flex: 1 1 120px;\n    max-width: 140px;\n\n    box-sizing: border-box;\n  }\n\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skill": `Skill_skill__l3rDc`,
	"test": `Skill_test__q0-7R`,
	"test2": `Skill_test2__D01aZ`
};
export default ___CSS_LOADER_EXPORT___;
