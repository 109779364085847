// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormItem_form-item__nflp3 {
  position: relative;
  height: fit-content;
  width: fit-content;
  align-self: end;
}
.FormItem_form-item__nflp3 :nth-of-type(2) {
  font-size: 12px;
  color: #f6f6f6;
  opacity: 0.75;
}
.FormItem_form-item__nflp3 label {
  position: absolute;
  color: #f6f6f6;
  bottom: 20px;
  left: 10px;
  font-size: 16px;
  transition: bottom 0.25s ease, font-size 0.25s ease, left 0.25s ease, top 0.25s ease;
  cursor: text;
}
.FormItem_form-item__nflp3 input {
  background: none;
  border: none;
  color: #f6f6f6;
  font-size: 20px;
  height: 35px;
  width: 260px;
  border-bottom: 1px solid #4b4b4b;
}
.FormItem_form-item__nflp3 textarea {
  height: 340px;
  width: 100%;
  background: none;
  color: #f6f6f6;
  font-size: 14px;
  padding: 25px;
  border: 1px solid #4b4b4b;
  border-radius: 15px;
  resize: none;
}

@media (max-width: 1180px) {
  .FormItem_form-item__nflp3 {
    width: 100%;
  }
  .FormItem_form-item__nflp3 input {
    width: 100%;
  }
}
/* @media (max-width: 1680px) {
  .form-item input{
    width: 220px;
  }

  .form-item textarea{
    width: 550px;
  }

}

@media (max-width: 1130px) {
  .form-item{
    margin-bottom: 60px;

    width: 100%;
  }

  .form-item input{
    width: 100%;
  }


  .form-item textarea{

    width: 100%;
  }

}

 */`, "",{"version":3,"sources":["webpack://./src/components/FormItem.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EAEA,kBAAA;EACA,eAAA;AAAF;AAGE;EACE,eAAA;EACA,cAAA;EAEA,aAAA;AAFJ;AAKE;EACE,kBAAA;EACA,cAAA;EAEA,YAAA;EACA,UAAA;EAEA,eAAA;EAEA,oFAAA;EACA,YAAA;AANJ;AASE;EACE,gBAAA;EACA,YAAA;EAEA,cAAA;EACA,eAAA;EAEA,YAAA;EACA,YAAA;EAEA,gCAAA;AAVJ;AAaE;EACE,aAAA;EACA,WAAA;EAEA,gBAAA;EAEA,cAAA;EAEA,eAAA;EAEA,aAAA;EAEA,yBAAA;EACA,mBAAA;EAEA,YAAA;AAjBJ;;AAqBA;EACE;IACE,WAAA;EAlBF;EAoBE;IACE,WAAA;EAlBJ;AACF;AAwBA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EAAA","sourcesContent":[".form-item{\n  position: relative;\n  height: fit-content;\n\n  width: fit-content;\n  align-self: end;\n\n\n  :nth-of-type(2){\n    font-size: 12px;\n    color: #f6f6f6;\n\n    opacity: 0.75;\n  }\n\n  label{\n    position: absolute;\n    color: #f6f6f6;\n\n    bottom: 20px;\n    left: 10px;\n\n    font-size: 16px;\n\n    transition: bottom 0.25s ease, font-size 0.25s ease, left 0.25s ease, top 0.25s ease;\n    cursor: text;\n  }\n\n  input{\n    background: none;\n    border: none;\n\n    color: #f6f6f6;\n    font-size: 20px;\n    \n    height: 35px;\n    width: 260px;\n\n    border-bottom: 1px solid #4b4b4b;\n  }\n\n  textarea{\n    height: 340px;\n    width: 100%;\n\n    background: none;\n\n    color: #f6f6f6;\n\n    font-size: 14px;\n\n    padding: 25px;\n  \n    border: 1px solid #4b4b4b;\n    border-radius: 15px;\n\n    resize: none;\n  }\n}\n\n@media (max-width: 1180px) {\n  .form-item {\n    width: 100%;\n\n    input {\n      width: 100%;\n    }\n  }\n\n}\n\n\n/* @media (max-width: 1680px) {\n  .form-item input{\n    width: 220px;\n  }\n\n  .form-item textarea{\n    width: 550px;\n  }\n\n}\n\n@media (max-width: 1130px) {\n  .form-item{\n    margin-bottom: 60px;\n\n    width: 100%;\n  }\n\n  .form-item input{\n    width: 100%;\n  }\n\n\n  .form-item textarea{\n\n    width: 100%;\n  }\n\n}\n\n */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-item": `FormItem_form-item__nflp3`
};
export default ___CSS_LOADER_EXPORT___;
