// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterItem_filter__gA7i6 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #131313;
  height: 60px;
  width: 60px;
  border: 1px solid #363636;
  border-radius: 12px;
  transition: height 0.2s ease;
  z-index: 1;
  margin-bottom: 10px;
}
.FilterItem_filter__gA7i6 > div {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bebebe;
  font-size: 14px;
  font-weight: 500;
  height: 58px;
  width: 100%;
  border-radius: 12px;
  transition: opacity 0.2s ease, margin-top 0.2s ease;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}
.FilterItem_filter__gA7i6 > div:hover {
  background: #1a1a1a;
}
.FilterItem_filter__gA7i6 > div:active {
  background: #202020;
}
.FilterItem_filter__gA7i6 > div img {
  width: 22px;
  margin-right: 0.5px;
}

@media (max-width: 1680px) {
  .FilterItem_reduce-width__-kvbR {
    width: 220px !important;
  }
  .FilterItem_reduce-width2__TbAtY {
    width: 210px !important;
    border: 1px solid red;
  }
  .FilterItem_full-width__\\+uVXG {
    width: 98% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/FilterItem.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EAEA,aAAA;EACA,sBAAA;EAEA,mBAAA;EAEA,mBAAA;EAEA,YAAA;EACA,WAAA;EAEA,yBAAA;EACA,mBAAA;EAEA,4BAAA;EAEA,UAAA;EACA,mBAAA;AARF;AAUC;EACC,kBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,cAAA;EACA,eAAA;EACA,gBAAA;EAEA,YAAA;EACA,WAAA;EAEA,mBAAA;EAEA,mDAAA;EACA,yBAAA;UAAA,iBAAA;EAEA,eAAA;AAdF;AAgBE;EACE,mBAAA;AAdJ;AAiBE;EACE,mBAAA;AAfJ;AAkBE;EACE,WAAA;EACA,mBAAA;AAhBJ;;AAuBA;EACE;IACE,uBAAA;EApBF;EAuBA;IACE,uBAAA;IACA,qBAAA;EArBF;EAwBA;IACE,qBAAA;EAtBF;AACF","sourcesContent":["\n\n.filter{\n  position: relative;\n\n  display: flex;\n  flex-direction: column;\n\n  align-items: center;\n\n  background: #131313;\n\n  height: 60px;\n  width: 60px;\n\n  border: 1px solid #363636;\n  border-radius: 12px;\n\n  transition: height 0.2s ease;\n\n  z-index: 1;\n  margin-bottom: 10px;\n\n >div{\n  position: absolute;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  color: #bebebe;\n  font-size: 14px;\n  font-weight: 500;\n\n  height: 58px;\n  width: 100%;\n\n  border-radius: 12px;\n\n  transition: opacity 0.2s ease, margin-top 0.2s ease;\n  user-select: none;\n\n  cursor: pointer;\n\n  &:hover{\n    background: #1a1a1a;\n  }\n\n  &:active{\n    background: #202020;\n  }\n\n  img {\n    width: 22px;\n    margin-right: 0.5px;\n  }\n\n }\n\n}\n\n@media (max-width: 1680px) {\n  .reduce-width{\n    width: 220px !important;\n  }\n\n  .reduce-width2{\n    width: 210px !important;\n    border: 1px solid red;\n  }\n\n  .full-width{\n    width: 98% !important;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `FilterItem_filter__gA7i6`,
	"reduce-width": `FilterItem_reduce-width__-kvbR`,
	"reduce-width2": `FilterItem_reduce-width2__TbAtY`,
	"full-width": `FilterItem_full-width__+uVXG`
};
export default ___CSS_LOADER_EXPORT___;
