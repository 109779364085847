// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent; /*To remove the blue background when tapping on links & icons on phone*/
  font-family: 'Roboto Slab', serif;
}

*:focus,
*:active {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.noUserInteraction {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

html {
  scroll-behavior: smooth;
  
  font-weight: 300;
  background: #0E0E0E;
  
}

html, body, #root{
  height: 100%;
  
  /* because of transation animation, putting it in .app will remove the sticky from placeholder*/
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;;EAEtB,wCAAwC,EAAE,uEAAuE;EACjH,iCAAiC;AACnC;;AAEA;;EAEE,wCAAwC;EACxC,aAAa;AACf;;AAEA;EACE,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,2BAA2B,EAAE,eAAe;EAC5C,yBAAyB,EAAE,WAAW,EACZ,mBAAmB,EACrB,4BAA4B,EAC7B,2BAA2B;EAClD,iBAAiB,EAAE,iFAAiF;AACtG;;AAEA;EACE,uBAAuB;;EAEvB,gBAAgB;EAChB,mBAAmB;;AAErB;;AAEA;EACE,YAAY;;EAEZ,+FAA+F;AACjG","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n\n  -webkit-tap-highlight-color: transparent; /*To remove the blue background when tapping on links & icons on phone*/\n  font-family: 'Roboto Slab', serif;\n}\n\n*:focus,\n*:active {\n  -webkit-tap-highlight-color: transparent;\n  outline: none;\n}\n\nbody {\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.noUserInteraction {\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Old versions of Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */\n}\n\nhtml {\n  scroll-behavior: smooth;\n  \n  font-weight: 300;\n  background: #0E0E0E;\n  \n}\n\nhtml, body, #root{\n  height: 100%;\n  \n  /* because of transation animation, putting it in .app will remove the sticky from placeholder*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
