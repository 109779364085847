// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavItem_navi-background__rZZpe {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background 0.25s ease, border 0.25s ease;
  background: #1a1a1a;
  border: 1px solid rgb(39, 39, 39);
}
.NavItem_navi-background__rZZpe:hover {
  background: #1f1f1f;
}
.NavItem_navi-background__rZZpe:active {
  background: #242424;
}
.NavItem_navi-background__rZZpe img {
  display: block;
  filter: invert(60%);
  transition: filter 0.75s ease;
}

@media (max-width: 950px) {
  .NavItem_navi-background__rZZpe {
    margin-bottom: 0px !important;
    margin-right: 10px;
  }
  .NavItem_navi-background__rZZpe:hover {
    background: none;
  }
  .NavItem_navi-background__rZZpe:active {
    background: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NavItem.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,YAAA;EACA,WAAA;EAEA,mBAAA;EAEA,mBAAA;EAEA,eAAA;EAEA,oDAAA;EAEA,mBAAA;EACA,iCAAA;AANF;AAQE;EACE,mBAAA;AANJ;AASE;EACE,mBAAA;AAPJ;AAUE;EACE,cAAA;EACA,mBAAA;EAEA,6BAAA;AATJ;;AAcA;EACE;IACE,6BAAA;IACA,kBAAA;EAXF;EAaE;IACE,gBAAA;EAXJ;EAaE;IACE,gBAAA;EAXJ;AACF","sourcesContent":["\n.navi-background{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  height: 50px;\n  width: 50px;\n\n  border-radius: 10px;\n  \n  margin-bottom: 15px;\n\n  cursor: pointer;\n\n  transition: background 0.25s ease, border 0.25s ease; \n\n  background: #1a1a1a;\n  border: 1px solid rgb(39, 39, 39);\n  \n  &:hover{\n    background: #1f1f1f;\n  }\n\n  &:active{\n    background: #242424;\n  }\n\n  img{\n    display: block; // fixes some sort of padding below the image\n    filter: invert(60%);\n\n    transition: filter 0.75s ease; \n  }\n}\n\n\n@media (max-width: 950px) {\n  .navi-background{ \n    margin-bottom: 0px !important;\n    margin-right: 10px;\n\n    &:hover{\n      background: none;\n    }\n    &:active{\n      background: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navi-background": `NavItem_navi-background__rZZpe`
};
export default ___CSS_LOADER_EXPORT___;
