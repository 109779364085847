// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LetterBoxes_letterbox-wrapper__BlCnm {
  display: flex;
  padding: 10px 0px;
}

.LetterBoxes_letterBox__Eo6zn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #292929;
  width: 24px;
  height: 29px;
  margin-right: 3px;
  border-radius: 5px;
}
.LetterBoxes_letterBox__Eo6zn :nth-child(1) {
  z-index: 1;
}
.LetterBoxes_letterBox__Eo6zn :nth-child(2) {
  position: absolute;
  top: 0px;
  background: #161616;
  height: 50%;
  width: 100%;
  z-index: 0;
  border-bottom: 1px solid #1b1b1b;
  border-radius: 5px 5px 0px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/LetterBoxes.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,iBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,aAAA;EAEA,mBAAA;EACA,uBAAA;EAEA,yBAAA;EAEA,WAAA;EACA,YAAA;EAEA,iBAAA;EAEA,kBAAA;AALF;AAOE;EACE,UAAA;AALJ;AAQE;EACE,kBAAA;EAEA,QAAA;EACA,mBAAA;EAEA,WAAA;EACA,WAAA;EAEA,UAAA;EAEA,gCAAA;EACA,8BAAA;AAVJ","sourcesContent":[".letterbox-wrapper{\n  display: flex;\n\n  padding: 10px 0px;\n}\n\n.letterBox{\n  position: relative;\n  display: flex;\n\n  align-items: center;\n  justify-content: center;\n\n  border: 1px solid #292929;\n\n  width: 24px;\n  height: 29px;\n\n  margin-right: 3px;\n\n  border-radius: 5px;\n\n  :nth-child(1){\n    z-index: 1;\n  }\n\n  :nth-child(2){\n    position: absolute;\n\n    top: 0px;\n    background: #161616;\n\n    height: 50%;\n    width: 100%;\n\n    z-index: 0;\n\n    border-bottom: 1px solid #1b1b1b;\n    border-radius: 5px 5px 0px 0px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"letterbox-wrapper": `LetterBoxes_letterbox-wrapper__BlCnm`,
	"letterBox": `LetterBoxes_letterBox__Eo6zn`
};
export default ___CSS_LOADER_EXPORT___;
